<template>
  <div
    class="relative"
    data-el="t3-ce-rawlplug-verticalboxes"
    data-test="t3-ce-rawlplug-verticalboxes"
  >
    <div
      data-el="t3-ce-rawlplug-verticalboxes-grid"
    >
      <div class="flex flex-col gap-2.5 pr-5 text-white lg:gap-5">
        <UiIcon
          v-if="props.icon"
          :name="props.icon"
          width="60"
          height="60"
        />

        <div class="mb-0 lg:mb-2.5">
          <UiHeader
            header-layout="section-header"
            header-style-class="text-white mb-0"
            :header="props.header"
          />

          <HtmlParser
            v-if="props.subheader"
            :html="props.subheader"
            class="mt-2.5 text-lg font-light leading-8 lg:mt-6 lg:text-xl"
          />
        </div>

        <p class="pb-2 text-base font-normal leading-6 lg:pb-4">
          {{ props.description }}
        </p>

        <UiButton
          v-if="props.button?.text && props.button.link?.href"
          class="hidden w-fit border-white text-white lg:inline-flex"
          :class="buttonClass"
          :variant="buttonVariant"
          :to="props.button.link?.href"
          icon-right="arrow"
        >
          {{ props.button?.text }}
        </UiButton>
      </div>

      <div class="-mr-8 lg:hidden xl:mr-0">
        <UiSliderHorizontal
          class="swiper-main"
          classes="swiper-main"
        >
          <UiServiceBox
            v-for="(box, index) in boxes"
            :key="`box-${index}`"
            :icon="box.icon"
            :title="box.header ?? ''"
            :subtitle="box.description ?? ''"
            overlay-class="bg-transparent"
            class="h-full"
            :button="{
              btnText: '',
              link: box.link?.href ?? '',
              target: box.link?.target,
            }"
            :image="{
              src: box.image?.publicUrl,
            }"
            :image-mobile="{
              src: box.image?.publicUrl,
            }"
          />
        </UiSliderHorizontal>
      </div>

      <UiServiceBox
        v-for="(box, index) in boxes"
        :key="`box-${index}`"
        :icon="box.icon"
        :title="box.header ?? ''"
        :subtitle="box.description ?? ''"
        overlay-class="bg-transparent"
        class="hidden lg:block"
        :class="background"
        :button="{
          btnText: t('see_more'),
          link: box.link?.href ?? '',
          target: box.link?.target,
        }"
        :image="{
          src: box.image?.publicUrl ?? '',
        }"
        :image-mobile="{
          src: box.image?.publicUrl ?? '',
        }"
      />
    </div>

    <UiButton
      v-if="props.button?.text && props.button.link?.href"
      class="mt-7 inline-flex border-white text-white lg:hidden"
      :class="buttonClass"
      :variant="buttonVariant"
      icon-right="arrow"
    >
      {{ props.button?.text }}
    </UiButton>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import UiServiceBox from '@ui/components/UiServiceBox/UiServiceBox.vue'
import UiSliderHorizontal from '@ui/components/UiSlider/UiSliderHorizontal.vue'
import UiButton from '@ui/components/UiButton/UiButton.vue'
import UiIcon from '@ui/components/UiIcon/UiIcon.vue'
import UiHeader from '@ui/components/UiHeader/UiHeader.vue'
import { T3FrameAppearanceKey } from '@cms/components/T3Frame/T3Frame.types'
import type { T3CeRawlplugVerticalboxesProps } from './T3CeRawlplugVerticalboxes.types'
import HtmlParser from '~/modules/rawlplug-base/components/HtmlParser/HtmlParser.vue'

const props = defineProps<T3CeRawlplugVerticalboxesProps>()

const { background } = inject(T3FrameAppearanceKey) || {}

const { t } = useI18n()
const boxes = computed(() => props.boxes ?? [])

const buttonVariant = computed(() => {
  switch (props.button?.style) {
    case 'with-border':
      return 'secondary'
    default:
      return 'link'
  }
})

const buttonClass = computed(() => {
  return buttonVariant.value === 'link'
    ? 'normal-case underline-offset-4 hover:!underline pb-0.5'
    : 'w-full lg:w-fit'
})
</script>

<style lang="postcss">
[data-el='t3-ce-rawlplug-verticalboxes-grid'] {
  @apply grid grid-cols-1 gap-6;

  @media screen(lg) {
    grid-template-columns: 343px repeat(3, 1fr);
  }
}

[data-el='t3-ce-rawlplug-verticalboxes'] .swiper-slide {
  @apply !h-auto;
}
</style>
